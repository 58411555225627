import React, { useState, useEffect } from "react";
import useProjects from "../hooks/useProjects";
import Project from "./Project";
import styled from "@emotion/styled";
//TODO: desinstalar Aos?

const ProjectsPage = styled.div`
  max-width: 1440px;
`;

const ProjectsTitle = styled.h3`
  font-size: 4.5rem;
  font-weight: 700;
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--accent-light);
  margin: 2rem 0;
  background-color: var(--background-theme);
`;

const ProjectsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0;
`;

const ProjectsList = () => {
  const result = useProjects();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(result);
  }, []);

  return (
    <ProjectsPage>
      <ProjectsTitle>PROJECTS</ProjectsTitle>
      <ProjectsContainer>
        {[...projects].reverse().map(project => (
          <Project key={project.id} project={project} />
        ))}
      </ProjectsContainer>
    </ProjectsPage>
  );
};

export default ProjectsList;
