import { graphql, useStaticQuery } from "gatsby";

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProjects {
        nodes {
          strapiId
          Title
          Description
          ProjectImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
          ProjectURL
          GitHubURL
          GitHubBackend
          technologies {
            Title
            id
            Icon {
              localFile {
                publicURL
              }
            }
            project
          }
          workingside {
            Title
            Icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  return data.allStrapiProjects.nodes.map(project => ({
    title: project.Title,
    description: project.Description,
    image: project.ProjectImage.localFile.childImageSharp,
    projecturl: project.ProjectURL,
    github_url: project.GitHubURL,
    github_backend_url: project.GitHubBackend,
    id: project.strapiId,
    workingside: project.workingside,
    technologies: project.technologies,
  }));
};

export default useProjects;
