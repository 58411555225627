import React from "react";
import Layout from "../components/layout/Layout";
import ProjectsList from "../components/ProjectsList";

const Projects = () => {
  return (
    <Layout>
      <ProjectsList />
    </Layout>
  );
};

export default Projects;
