import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 100vw;
  height: 72rem;
  margin: 2rem 1.5rem;
  box-shadow: 0 0 0.5rem 0.3rem var(--accent-light);
  background-color: var(--background-theme);
  border: 0.3rem solid;
  border-radius: 2rem;

  h4 {
    font-size: 3.5rem;
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0.5rem;
    text-align: center;
    margin: 1.5rem 0;
  }
  @media (min-width: 415px) {
    height: 90rem;
    justify-content: space-around;
  }
  @media (min-width: 768px) {
    width: 45%;
    height: 80rem;
  }
  @media (min-width: 1280px) {
    width: 30%;
  }
`;

const ProjectInfo = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  > img {
    width: 95%;
    border: 2px solid white;
  }
`;

const ProjectDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 17rem;
  padding: 1rem 2rem;
  color: white;
  p {
    font-size: 2.2rem;
    line-height: 1.5;
  }
`;

const ProjectTechnologies = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  height: 8rem;
  margin: 1rem 0;
  background-color: var(--background-technologies);
  border-radius: 1rem;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 7rem;
    height: 7rem;
    background-color: white;
    border-radius: 50%;

    img {
      max-height: 50%;
      width: auto;
    }
  }
`;

const Role = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 95%;
  background-color: var(--background-role);
  border-radius: 1rem;
  p {
    font-size: 2.5rem;
    text-align: center;
    color: black;
    margin-right: 1rem;
  }
  img {
    height: 4rem;
    width: 4rem;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  height: 12rem;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 1rem 0.4rem;
    text-align: center;
    font-family: "Kanit", sans-serif;
    border-radius: 2rem;
  }
  .btn-project {
    background-color: var(--bg-btn-project);
    color: var(--text-btn-project);
    font-size: 1.7rem;
    &:hover {
      background-color: var(--accent-dark);
      color: white;
    }
  }

  .btn-github1 {
    background-color: var(--bg-btn-github1);
    color: var(--text-btn-github1);
    font-size: 1.7rem;
    &:hover {
      background-color: #000000;
      color: #ffffff;
      box-shadow: 0 0 0.5rem 0.2rem white;
    }
  }
  .btn-github2 {
    color: var(--text-btn-github2);
    background-color: var(--bg-btn-github2);
    font-size: 1.7rem;
    &:hover {
      box-shadow: 0 0 0.5rem 0.2rem white;
      color: var(--accent-light);
    }
  }
`;

const Project = ({ project }) => {
  const {
    title,
    description,
    image,
    id,
    projecturl,
    github_url,
    github_backend_url,
    workingside,
    technologies,
  } = project;

  const picture = getImage(image);
  return (
    <ProjectContainer id={id}>
      <h4>{title}</h4>
      <ProjectInfo>
        <GatsbyImage image={picture} alt={title} />

        <ProjectDescription>
          <p>{description}</p>
        </ProjectDescription>
        <ProjectTechnologies>
          {technologies.map(technology => (
            <div key={technology.id}>
              <img
                src={technology.Icon.localFile.publicURL}
                alt={technology.Title}
              />
            </div>
          ))}
        </ProjectTechnologies>
        <Role>
          <p>{workingside.Title}</p>
          <img
            src={workingside.Icon.localFile.publicURL}
            alt={workingside.Title}
          />
        </Role>

        <ButtonsContainer>
          <a
            className="btn-project"
            href={projecturl}
            target="_blank"
            rel="noreferrer"
          >
            To the Project
          </a>
          <a
            className="btn-github1"
            href={github_url}
            target="_blank"
            rel="noreferrer"
          >
            To GitHub Repository
          </a>
          {github_backend_url === null ? null : (
            <a
              className="btn-github2"
              href={github_backend_url}
              target="_blank"
              rel="noreferrer"
            >
              To GitHub Repository (backend)
            </a>
          )}
        </ButtonsContainer>
      </ProjectInfo>
    </ProjectContainer>
  );
};

export default Project;
